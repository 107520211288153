@import "atoms/variables";@import "functions";
.contents {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 3rem;

  & > div {
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: $max-content-width;

    @media (max-width: $mobile-width) {
      grid-template-columns: 100%;
    }

    & > .section1 {
      width: 100%;

      @media (max-width: $mobile-width) {
        display: flex;
        flex-direction: column;
      }

      & > h2 {
        font-family: $main-font;
        font-style: normal;
        font-weight: 700;
        font-size: 58px;
        line-height: 100.5%;
        color: $blue-primary-700;
        @media (max-width: $mobile-width) {
          text-align: center;
          font-size: 28px;
        }
      }

      & > p {
        font-family: $main-font;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.0025em;
        @media (max-width: $mobile-width) {
          font-size: 16px;
          margin: 1rem;
        }
      }
      & > div {
        padding: 1rem;
        display: flex;
        align-items: center;
        width: 500px;

        @media (max-width: $mobile-width) {
          justify-content: center;
          width: 100%;
        }

        & > img {
          width: 100%;
          height: auto;
        }
      }
    }
    & > .section2 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 500px;

      & > img {
        position: absolute;
        width: 700px;
        height: 620px;
        margin-left: 350px;
      }

      @media (max-width: $mobile-width) {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        height: 420px;
        & > img {
          position: unset;
          width: 400px;
          height: auto;
          transform: scale(1.6);
          margin-left: 0px;
        }
        & > div {
          display: none;
        }
      }
    }
  }
}
